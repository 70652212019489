import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <h1>
        Out of Business
      </h1>

      <p className="Closed">
        Due to the losses caused to business by the coronavirus epidemic, the company was liquidated.
        The products are no longer for sale. We are grateful to all our customers for their trust and support.
      </p>

      <h2>Impressum</h2>

      <h3>DRUGOE DELO GmbH</h3>

      <address>
        Pappelallee 78/79<br />
        10437 Berlin
      </address>

      <p>
        Telefon: <code>+49 170 148 8491</code><br />
        E-Mail: <code>info@drugoe.de</code><br />
        Internet: <code>https://drugoe.de</code>
      </p>

      <p>
        Vertretungsberechtigte Geschäftsführerin: Tasha Orbita<br />
        Registergericht: Amtsgericht Charlottenburg
      </p>

      <p>
        Registernummer: <code>HRB 214581 B</code><br />
        Steuernummer: <code>37 267 50934</code><br />
        Umsatzsteuer-ID-Nr.: <code>DE313698766</code>
      </p>

      <h3>Postbank Ndl der Deutsche Bank</h3>

      <p>
        BIC / SWIFT: <code>PBNKDEFFXXX</code><br />
        IBAN: <code>DE04 1001 0010 09XX XXXX XX</code>
      </p>

      <h3>Gegenstand des Unternehmens</h3>

      <p>
        IT-Development und Entwicklung, insbesondere die Entwicklung von Software und Design, Handel mit Waren aller
        Art, insbesondere mit Software sowie die Beratung auf den vorbezeichneten Gebieten, immer soweit es keiner
        behördlichen Genehmigung bedarf.
      </p>
    </div>
  );
}

export default App;
